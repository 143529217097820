import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './Home';
import Apps from './Apps'; // Import the Apps component

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/apps" element={<Apps />} /> {/* Add the Apps route */}
      </Routes>
    </Router>
  );
}

export default App;
