import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './Apps.css';

function Apps() {
  const navigate = useNavigate();

  useEffect(() => {
    const shapes = document.querySelectorAll('.shape');
    const maxDistance = 100;
    const screenWidth = window.innerWidth;
    const screenHeight = window.innerHeight;

    shapes.forEach((shape) => {
      const randomX = Math.random() * (screenWidth - 60);
      const randomY = Math.random() * (screenHeight - 60);
      shape.style.left = `${randomX}px`;
      shape.style.top = `${randomY}px`;
    });

    const handleMouseMove = (e) => {
      const mouseX = e.clientX;
      const mouseY = e.clientY;

      shapes.forEach((shape) => {
        const rect = shape.getBoundingClientRect();
        const shapeX = rect.left + rect.width / 2;
        const shapeY = rect.top + rect.height / 2;

        const deltaX = shapeX - mouseX;
        const deltaY = shapeY - mouseY;

        const distance = Math.sqrt(deltaX * deltaX + deltaY * deltaY);

        if (distance < maxDistance) {
          const angle = Math.atan2(deltaY, deltaX);
          const moveDistance = maxDistance - distance;

          let offsetX = Math.cos(angle) * moveDistance;
          let offsetY = Math.sin(angle) * moveDistance;

          let newLeft = shapeX + offsetX;
          let newTop = shapeY + offsetY;

          if (newLeft < 0) newLeft = screenWidth;
          if (newLeft > screenWidth) newLeft = 0;
          if (newTop < 0) newTop = screenHeight;
          if (newTop > screenHeight) newTop = 0;

          shape.style.left = `${newLeft}px`;
          shape.style.top = `${newTop}px`;
        }
      });
    };

    window.addEventListener('mousemove', handleMouseMove);

    return () => {
      window.removeEventListener('mousemove', handleMouseMove);
    };
  }, []);

  return (
    <div className="container">
      <div className="background-shapes">
        {[...Array(10)].map((_, i) => (
          <div key={i} className="shape"></div>
        ))}
      </div>
      <div className="header">
        <div className="name">
          <h1>Daniel</h1>
          <h1>Stone</h1>
        </div>
        <img
          src={`${process.env.PUBLIC_URL}/ravens_logo.jpg`}
          alt="Raven's Logo"
          className="avatar"
          onClick={() => navigate('/')} // Navigate to home on click
          style={{ cursor: 'pointer' }} // Show pointer cursor on hover
        />
      </div>
      <h2 className="title">Apps</h2>
      <div className="buttons">
        <a href="/apps/rsa-v" target="_blank" className="button">RSA-V</a>
        <a href="/apps/ds4" target="_blank" className="button">DS4</a>
        <a href="/apps/tdc" target="_blank" className="button">TDC</a>
        <a href="/apps/meritx" target="_blank" className="button">MeritX</a>
        <a href="/apps/polix" target="_blank" className="button">PoliX</a>
      </div>
      <div className="footer">
        <a href="https://github.com/manjingero" target="_blank" rel="noopener noreferrer">
          <img src={`${process.env.PUBLIC_URL}/github_logo.png`} alt="GitHub" className="github-icon" />
        </a>
      </div>
    </div>
  );
}

export default Apps;
